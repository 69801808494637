"use client";

import { useEffect, useState, useCallback } from "react";
import { useNavigator } from "../hooks/useNavigator";
import { useMe } from "~/hooks/useMe";
import { useFeatureGate } from "~/hooks/useRemoteConfig";

const CustomerIOTracker: React.FC = () => {
  useEffect(() => {
    (window as any).eventBus = undefined;

    if ((window as any).webkit && (window as any).webkit.messageHandlers) {
      (window as any).eventBus = {
        callHandler: (handlerName: string, args: any) => {
          if (
            (window as any).webkit.messageHandlers.hasOwnProperty(handlerName)
          ) {
            (window as any).webkit.messageHandlers[handlerName].postMessage(
              args,
            );
          }
        },
      };
    } else if (
      (window as any).flutter_inappwebview &&
      (window as any).flutter_inappwebview.callHandler
    ) {
      (window as any).eventBus = {
        callHandler: (handlerName: string, args: any) => {
          (window as any).flutter_inappwebview.callHandler(handlerName, args);
        },
      };
    } else {
      (window as any).eventBus = undefined;
    }
  }, []);

  useEffect(() => {
    /*
    Initialization script according to:
    https://baccini-al.medium.com/how-to-integrate-customer-ios-tracking-code-in-your-react-app-with-ts-1df0b959a808
    https://docs.customer.io/sdk/javascript/getting-started/#install
    */
    if (!process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID) {
      return;
    }

    // This check is of critical importance because the customer.io SDK will make your
    // app crash if you try to initialize it twice.
    if ((window as any)._cio && Array.isArray((window as any)._cio)) {
      return;
    }

    const _cio: any = [];
    (window as any)._cio = _cio;

    let c: number;

    const a: (f: string) => (...args: any[]) => void = function (f) {
      return function (...args: any[]) {
        _cio.push([f].concat(args));
      };
    };

    const b: string[] = [
      "load",
      "identify",
      "sidentify",
      "track",
      "page",
      "on",
      "off",
    ];
    for (c = 0; c < b.length; c++) {
      _cio[b[c]] = a(b[c]);
    }

    const t = document.createElement("script"),
      s = document.getElementsByTagName("script")[0];
    t.async = true;
    t.id = "cio-tracker";
    t.setAttribute("data-site-id", process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID);
    t.setAttribute("data-use-array-params", "true");
    t.setAttribute("data-use-in-app", "true");
    t.src = "https://assets.customer.io/assets/track-eu.js";
    s.parentNode.insertBefore(t, s);
  }, []);

  return null;
};

const CustomerIOProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isCustomerioReady, setCustomerioReady] = useState<boolean>(false);
  const [isScriptReady, setScriptReady] = useState<boolean>(false);
  const { value: isFeatureOn } = useFeatureGate("customer-io");
  const { value: isPushNotificationsOn } = useFeatureGate(
    "customer-io-push-notifications",
  );

  const { url } = useNavigator();
  const { me } = useMe();

  useEffect(() => {
    if (!me || !isFeatureOn || !process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID) {
      return;
    }
    const _cio = (window as any)._cio || undefined;
    (window as any)._cio = _cio;

    if (!_cio) {
      return;
    }

    _cio.identify({
      id: me.id, // Email of the currently signed in user.
      email: me.email, // Email of the currently signed in user.
      first_name: me.profile ? me.profile.firstName : undefined, // Email of the currently signed in user.
      last_name: me.profile ? me.profile.lastName : undefined, // Email of the currently signed in user.
    });
    setScriptReady(true);
  }, [me, isFeatureOn, setScriptReady]);

  useEffect(() => {
    if (
      !isCustomerioReady &&
      isPushNotificationsOn &&
      (window as any).eventBus &&
      (window as any).eventBus.callHandler &&
      me &&
      me.id
    ) {
      (window as any).eventBus.callHandler("customerio", {
        id: me.id,
        siteId: process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID,
        apiKey: process.env.NEXT_PUBLIC_CUSTOMER_IO_API_KEY,
      });
      setCustomerioReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPushNotificationsOn,
    me,
    isCustomerioReady,
    (window as any).eventBus,
    (window as any).eventBus?.callHandler,
  ]);

  const onRouteChangeComplete = useCallback((url: any) => {
    const _cio = (window as any)._cio || [];
    (window as any)._cio = _cio;
    _cio.track("page", url);
  }, []);

  useEffect(() => {
    if (isScriptReady) {
      onRouteChangeComplete(url);
    }
  }, [url, isScriptReady, onRouteChangeComplete]);

  if (!me) {
    return <>{children}</>;
  }

  return <>{children}</>;
};

export { CustomerIOTracker, CustomerIOProvider };
