"use client";

import { exists } from "~/utils/exists";
import Script from "next/script";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMe } from "~/hooks/useMe";
import { useToast } from "~/hooks/useToast";
import { useCommonDict } from "~/hooks/useTranslations";
import { UserAccountType } from "~/lib/gql/me/get-me.gql";

const CHAT_SUPPORT_SCRIPT_SRC = "/guest/js/intercom.min.js";

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

interface UserData {
  email?: string;
  user_id?: string;
  name?: string;
}

interface SupportChat {
  openChat(): void;
  unreadCount: number;
}

const SupportChatContext = createContext<SupportChat>({} as SupportChat);

export const SupportChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { me } = useMe();
  const { t } = useCommonDict();
  const toast = useToast();

  const [isChatTriggered, setChatTriggered] = useState<boolean>(false);
  const [isScriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const [isChatInitialized, setChatInitialized] = useState<boolean>(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);

  const isProductionEnv = process.env.NEXT_PUBLIC_ENV === "prod";

  const initChat = useCallback(() => {
    setScriptLoaded(true);

    if (exists(window) && window.Intercom) {
      console.debug("Booting Intercom...");

      const userData = getUserData(me);

      window.intercomSettings = {
        api_base: "https://api-iam.eu.intercom.io",

        app_id: isProductionEnv ? "qffkqntn" : "ecgwy1c2",
        hide_default_launcher: true,
        ...userData,
      };

      window.Intercom("onUnreadCountChange", (unreadCount: any) => {
        setUnreadMessagesCount(unreadCount);
      });

      setChatInitialized(true);

      console.debug("Booting Intercom completed");
      console.debug(`Intercom user: ${userData}`);

      window.Intercom("show");
    }
  }, [me, setChatInitialized]);

  useEffect(() => {
    if (window?.Intercom && me && isChatInitialized) {
      const userData = getUserData(me);
      window.Intercom("update", userData);
      console.debug(`Updated Intercom user: ${userData}`);
    }
  }, [isChatInitialized, me]);

  const openChat = () => {
    if (!isChatTriggered) {
      setChatTriggered(true);
      return;
    }

    if (!isChatInitialized) {
      initChat();
      return;
    }

    if (!isScriptLoaded || !isChatInitialized || !window.Intercom) {
      toast.error(t("fpErrorTitle"), { description: t("fpErrorDesc") });
      return;
    }

    window.Intercom("show");
  };

  return (
    <SupportChatContext.Provider
      value={{
        openChat,
        unreadCount: unreadMessagesCount,
      }}
    >
      {children}

      {isChatTriggered && (
        <Script src={CHAT_SUPPORT_SCRIPT_SRC} onLoad={initChat} defer />
      )}
    </SupportChatContext.Provider>
  );
};

export function useSupportChat(): SupportChat {
  const context = useContext(SupportChatContext);
  return context;
}

const getUserData = (me?: UserAccountType): UserData => {
  const { firstName, lastName } = me?.profile ?? {};

  const userData = {
    email: me?.email ?? undefined,
    user_id: me?.sub ?? undefined,
    name: [firstName, lastName].filter(exists).join(" ").trim() || undefined,
  };

  userData.toString = function () {
    return JSON.stringify(this, null, 2);
  };

  return userData;
};
