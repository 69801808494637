import { ReadonlyURLSearchParams } from "next/navigation";
import {
  CommunityTabs,
  CoworkerTabs,
  TeamDetailsTabs,
  TeamTabs,
} from "./navigationTypes";

export const appRoutes = {
  account: () => "/account",
  addCard: () => "/account/payments/add-card",
  pastBookings: () => "/account/past-bookings",
  book: () => "/book",
  community: (tab: CommunityTabs) => `/community?tab=${tab}`,
  coworkers: (tab: CoworkerTabs) => `/community/network/coworkers?tab=${tab}`,
  home: () => "/home",
  integrations: () => "/account/integrations",
  notifications: () => "/account/notifications",
  onboardingNotifications: () => "/onboarding/notifications",
  payments: () => "/account/payments",
  privacy: () => "/account/privacy",
  profile: () => "/account/profile",
  profileDetails: (id: string) => `/profile/${id}`,
  saved: () => "/saved",
  searchResults: (searchParams: ReadonlyURLSearchParams) =>
    `/search?${searchParams.toString()}`,
  searchWizard: () => "/search/wizard",
  teams: (tab: TeamTabs) => `/community/network/teams?tab=${tab}`,
  teamCreate: () => "/community/network/teams/create",
  teamDetails: (teamId: string) => `/community/network/teams/${teamId}`,
  teamEdit: (tab: TeamDetailsTabs, teamId: string) =>
    `/community/network/teams/edit/${teamId}?tab=${tab}`,
  workspaceDetails: (id: string) => `/workspace/${id}`,
};
