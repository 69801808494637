const location = typeof window !== 'undefined' ? window.location : null;

export const cookieConsentConfig = {
  cookieVersion: 6,
  barTimeout: 1000,
  theme: {
    barColor: '#cfceff',
    barTextColor: '#150038',
    barMainButtonColor: '#ff4800',
    barMainButtonTextColor: '#FFF',
    modalMainButtonColor: '#ff4800',
    modalMainButtonTextColor: '#FFF',
  },
  language: {
    current: 'en',
    locale: {
      en: {
        barMainText:
          'This app uses cookies to ensure you get the best experience.',
        closeAriaLabel: 'close',
        barLinkSetting: 'Cookie Settings',
        barBtnAcceptAll: 'Accept all cookies',
        modalMainTitle: 'Cookie settings',
        modalMainText:
          "Cookies are small pieces of data sent from a website and stored on the user's computer by the user's web browser while the user is browsing. Your browser stores each message in a small file, called cookie. When you request another page from the server, your browser sends the cookie back to the server. Cookies were designed to be a reliable mechanism for websites to remember information or to record the user's browsing activity.",
        modalBtnSave: 'Save current settings',
        modalBtnAcceptAll: 'Accept all cookies and close',
        modalAffectedSolutions: 'Affected solutions:',
        learnMore: 'Learn More',
        on: 'On',
        off: 'Off',
        enabled: 'is enabled.',
        disabled: 'is disabled.',
        checked: 'checked',
        unchecked: 'unchecked',
      },
    },
  },
  // List all the categories you want to display.
  categories: {
    necessary: {
      needed: true,
      wanted: true,
      checked: true,
      language: {
        locale: {
          en: {
            name: 'Strictly Necessary Cookies',
            description:
              'Without these cookies the application will not be working reliably.',
          },
        },
      },
    },
    monitoring: {
      needed: false,
      wanted: false,
      checked: false,
      language: {
        locale: {
          en: {
            name: 'Monitoring',
            description: 'User monitoring',
          },
        },
      },
    },
    analytics: {
      needed: false,
      wanted: false,
      checked: false,
      language: {
        locale: {
          en: {
            name: 'Analytics',
            description:
              'Used to gather information about user experience. Data is used only internally.',
          },
        },
      },
    },
  },
  // List actual services here.
  services: {
    googleAnalytics: {
      category: 'analytics',
      type: 'dynamic-script',
      search: 'analytics',
      cookies: [
        {
          name: /^_ga/,
          domain: `${location?.hostname}`,
        },
      ],
      language: {
        locale: {
          en: {
            name: 'Google Analytics',
          },
        },
      },
    },
    userMonitoring: {
      category: 'monitoring',
      type: 'dynamic-script',
      search: '_lr_',
      cookies: [
        {
          name: /^_lr_/,
          domain: `${location?.hostname}`,
        },
      ],
      language: {
        locale: {
          en: {
            name: 'Datadog',
          },
        },
      },
    },
    segmentAnalytics: {
      category: 'analytics',
      type: 'dynamic-script',
      search: 'analytics.min.js',
      cookies: [
        {
          name: /.+/,
          domain: `.segment.com`,
        },
      ],
      language: {
        locale: {
          en: {
            name: 'Segment',
          },
        },
      },
    },
    chat: {
      category: 'necessary',
      type: 'dynamic-script',
      search: 'intercom',
      cookies: [{ name: /^intercom/, domain: `.${location?.hostname}` }],
      language: {
        locale: {
          en: {
            name: 'Intercom',
          },
        },
      },
    },
  },
};
