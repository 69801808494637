// Necessary if using App Router to ensure this file runs on the client
"use client";

import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useDynamicConfig } from "~/hooks/useRemoteConfig";
import { UserAccountType } from "~/lib/gql/me/get-me.gql";

const isLocalEnv = process.env.NEXT_PUBLIC_ENV === "local";
const isClientSide = typeof window !== "undefined";

let initialized = false;
const initialize = (config: any) => {
  if (initialized) {
    return;
  }

  initialized = true;

  config = config ?? {
    site: "datadoghq.eu",
    service: "guest-web-app",
    sessionSampleRate: 50,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  };

  const allowedTracingUrls = config.allowedTracingUrls ?? [];

  if (!isLocalEnv) {
    datadogRum.init({
      ...config,
      applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
      env: process.env.NEXT_PUBLIC_ENV,
      allowedTracingUrls: [
        /https:\/\/.*guest\/api\/v1\/graphql/,
        ...allowedTracingUrls,
      ],
    });
  }
};

export const setDDUser = (user: UserAccountType) => {
  if (isLocalEnv || !isClientSide || !user) {
    return;
  }

  datadogRum.setUser({
    email: user.email,
    id: user.id,
    name: user.name,
  });

  datadogRum.setUserProperty("organization-name", user.organization?.name);
  datadogRum.setUserProperty("organization-id", user.organization?.id);
  datadogRum.setUserProperty("segments", user.segmentIds?.join(","));
};

export default function DatadogInit() {
  const { isLoading, config } = useDynamicConfig("guest-datadog-config");

  useEffect(() => {
    if (isLoading) {
      return;
    }

    initialize(config?.value);
  }, [config, isLoading]);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null as any;
}
