import { InMemoryCache } from "@apollo/experimental-nextjs-app-support";
import { relayStylePagination } from "@apollo/client/utilities";
import {
  DayOccupation,
  RangeBookingUnit,
  ResourceAvailability,
} from "~/types/Resource";
import { TheTime } from "~/_libs/time/TheTime";
import { TheDateTime } from "~/_libs/time/TheDateTime";
import { TheInterval } from "~/_libs/time/TheInterval";
import { TheDay } from "~/_libs/time/TheDay";

export const createInMemoryCache = (): InMemoryCache => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          posts: relayStylePagination(),
          workspaces: {
            keyArgs: false,
            merge(existing = { list: [] }, incoming, { readField }) {
              const existingIds = new Set(
                existing.list.map((item: any) => readField("id", item)),
              );
              const filteredIncomingList = incoming.list.filter(
                (item: any) => !existingIds.has(item.id),
              );

              return {
                ...incoming,
                list: [...existing.list, ...filteredIncomingList],
              };
            },
          },
          booking: {
            read(_, { args, toReference }) {
              return toReference({
                id: args.bookingId,
                __typename: "Booking",
              });
            },
          },
          coworkers: {
            keyArgs: false,
            merge: (existing = { hasMore: false, list: [] }, incoming) => {
              return {
                ...incoming,
                list: [...existing.list, ...(incoming?.list ?? [])],
              };
            },
          },
          getBookingRequest: {
            read(_, { args, toReference }) {
              return toReference({
                id: args.bookingRequestId,
                __typename: "BookingRequestDecorated",
              });
            },
          },
          bookings: {
            keyArgs: false,
            merge: (existing = { bookings: [] }, incoming) => {
              return {
                ...incoming,
                bookings: [...existing.bookings, ...(incoming?.bookings ?? [])],
              };
            },
          },
          availableWorkspaces: {
            keyArgs: false,
            merge: (existing = { workspaces: [] }, incoming, { readField }) => {
              const existingIds = new Set(
                existing.workspaces.map((item: any) => {
                  const itemId = readField("id", item);
                  return itemId;
                }),
              );

              const filteredIncomingList = incoming?.workspaces?.filter(
                (item: any) => {
                  const itemId = readField("id", item);
                  return !existingIds.has(itemId);
                },
              );

              const result = {
                ...incoming,
                workspaces: [...existing.workspaces, ...filteredIncomingList],
              };

              return result;
            },
          },
        },
      },
      Address: {
        merge: true,
      },
      TeamMember: {
        keyFields: ["id", "isAdmin", "isOwner"],
      },
      Resource: {
        fields: {
          rangeBookingUnitList: {
            read(
              rangeBookingUnitList: RangeBookingUnit[],
              { readField },
            ): RangeBookingUnit[] {
              if (!rangeBookingUnitList || rangeBookingUnitList.length === 0) {
                return rangeBookingUnitList;
              }

              const availability: ResourceAvailability =
                readField("availability");

              if (
                !availability?.dayOccupation ||
                availability.dayOccupation.length === 0
              ) {
                return rangeBookingUnitList.map((range: RangeBookingUnit) => ({
                  ...range,
                  isUnavailable: null,
                }));
              }

              const occupations = dayOccupationWithIntervals(
                availability?.dayOccupation,
              );

              const day = occupations[0].interval.day;
              const result = rangeBookingUnitList.map(
                (range: RangeBookingUnit) => {
                  const time: TheTime = new TheTime(
                    range.startTime.hour,
                    range.startTime.minute,
                  );
                  const dayTime: TheDateTime = new TheDateTime(day, time);
                  const rangeInterval = TheInterval.fromDuration(
                    dayTime.toString(),
                    range.durationInMinutes,
                  );

                  const intersections =
                    rangeInterval.getIntersections(occupations);

                  const newRange = {
                    ...range,
                    isUnavailable: intersections.some(
                      (occupation) => occupation.status !== "AVAILABLE",
                    ),
                  };

                  return newRange;
                },
              );

              return result;
            },
          },
          usedUpCapacity: {
            read(_: any, { variables, readField }: any) {
              if (!variables?.date) {
                return 0;
              }

              const availability: ResourceAvailability =
                readField("availability");

              if (!availability?.dayOccupation) {
                return 0;
              }

              const date: TheDay = TheDay.parse(variables.date);
              const result = getUsedUpCapacity(
                availability?.dayOccupation,
                date,
                variables.time,
              );

              return result;
            },
          },
        },
      },
    },
  });
};

const getUsedUpCapacity = (
  dayOccupation: DayOccupation[],
  date: TheDay,
  time?: {
    from?: string;
    to?: string;
  },
) => {
  if (!dayOccupation || dayOccupation.length === 0) {
    return 0;
  }

  if (time?.from && time?.to) {
    const from = TheDateTime.fromDateAndTime(date, TheTime.parse(time.from));
    const to = TheDateTime.fromDateAndTime(date, TheTime.parse(time.to));
    const interval = TheInterval.fromTheDateTime(from, to);
    const occupations = dayOccupationWithIntervals(dayOccupation);
    const intersections = interval.getIntersections(occupations);
    return intersections.length === 0
      ? 0
      : Math.max(...intersections.map((i) => i.count));
  }

  return Math.min(...dayOccupation.map((occupation) => occupation.count));
};

const dayOccupationWithIntervals = (dayOccupation: DayOccupation[]) => {
  return dayOccupation.map((occupation) => ({
    ...occupation,
    interval: TheInterval.fromString(
      occupation.interval.startDate,
      occupation.interval.endDate,
    ),
  }));
};
