"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useMemo } from "react";
import {
  CommunityTabs,
  CoworkerTabs,
  TeamDetailsTabs,
  TeamTabs,
} from "./navigationTypes";
import { appRoutes } from "./appRoutes";

export const useNavigator = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { push } = useRouter();

  return useMemo(() => {
    const url = `${pathname}${
      searchParams.toString() ? `?${searchParams.toString()}` : ""
    }`;

    return {
      url,
      tab: searchParams.get("tab") as
        | CommunityTabs
        | CoworkerTabs
        | TeamTabs
        | TeamDetailsTabs
        | null,
      navigateTo: (url: string) => {
        push(url);
      },

      goToAddCard: () => push(appRoutes.addCard()),
      goToBook: () => push(appRoutes.book()),
      goToCommunity: (tab: CommunityTabs) => {
        push(appRoutes.community(tab));
      },
      goToCoworkers: (tab: CoworkerTabs) => push(appRoutes.coworkers(tab)),
      goToHome: () => push(appRoutes.home()),
      goToOnboardingNotifications: () =>
        push(appRoutes.onboardingNotifications()),
      goToPayments: () => push(appRoutes.payments()),
      goToProfileDetails: (id: string) => push(appRoutes.profileDetails(id)),
      goToSearchResults: () => push(appRoutes.searchResults(searchParams)),
      goToSaved: () => push(appRoutes.saved()),
      goToTeamDetails: (teamId: string) => push(appRoutes.teamDetails(teamId)),
      goToTeamEdit: (tab: TeamDetailsTabs, teamId: string) =>
        push(appRoutes.teamEdit(tab, teamId)),
      goToTeams: (tab: TeamTabs) => push(appRoutes.teams(tab)),
      goToWorkspaceDetails: (workspaceId: string) =>
        push(appRoutes.workspaceDetails(workspaceId)),

      removeQueryParams: (...params: string[]) => {
        const currentParams = new URLSearchParams(searchParams);

        const hasAny = params.some((item) => !!searchParams.get(item));
        if (!hasAny) {
          return;
        }

        params.forEach((param) => {
          currentParams.delete(param);
        });

        push(`${pathname}?${currentParams.toString()}`);
      },
      queryData: {
        newWindow: searchParams.get("newWindow"),
        returnTo: searchParams.get("returnTo"),
        profileId: searchParams.get("profileId"),
        workspaceId: searchParams.get("workspaceId"),
        resourceId: searchParams.get("resourceId"),
        workspaceName: searchParams.get("workspaceName"),
        resourceName: searchParams.get("resourceName"),
        resourcePreviewId: searchParams.get("resourcePreviewId"),
        startTime: searchParams.get("startTime"),
        endTime: searchParams.get("endTime"),
        time: searchParams.get("time"),
        date: searchParams.get("date"),
        communityTab: searchParams.get("tab") as CommunityTabs,
      },
      pathname,
    };
  }, [pathname, searchParams?.toString(), push]);
};
