"use client";

import { HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  ApolloClient,
} from "@apollo/experimental-nextjs-app-support";
import { createInMemoryCache } from "./InMemoryCache";

function makeClient() {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GQL_SERVER,
    fetchOptions: { cache: "no-store" },
    credentials: "include",
  });

  return new ApolloClient({
    cache: createInMemoryCache(),
    link: httpLink,
  });
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
