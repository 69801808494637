import { useState, useCallback } from 'react';

export type ComponentController = {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
};

export type ComponentControllerProps = {
  defaultVisible?: boolean;
};

export const useComponentController = ({
  defaultVisible,
}: ComponentControllerProps = {}): ComponentController => {
  const [isVisible, setVisible] = useState(defaultVisible ?? false);

  const show = useCallback(() => setVisible(true), [setVisible]);
  const hide = useCallback(() => setVisible(false), [setVisible]);

  return {
    isVisible,
    show,
    hide,
  };
};
