"use client";

import type { ApolloError, ObservableQuery } from "@apollo/client";
import type {
  GetMeResult,
  Organization,
  UserAccountType,
} from "~/lib/gql/me/get-me.gql";

import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import { GET_ME_QUERY } from "~/lib/gql/me/get-me.gql";
import { PaymentMethod } from "~/lib/gql/me/get-my-payment-methods.gql";
import { setDDUser } from "~/components/datadog-init";

type MeHook = {
  me?: UserAccountType;
  isLoading: boolean;
  error?: ApolloError;
  refetchMe: ObservableQuery["refetch"];
  isEmailVerified: boolean;
  isUserProfileCreated: boolean;
  isUserProfileValid: boolean;
  isLoggedIn: boolean;
  userId?: string;
  organization?: Organization;
  paymentMethod?: PaymentMethod;
};

export const useMe = (): MeHook => {
  const { data, error, loading, refetch } = useQuery<GetMeResult>(GET_ME_QUERY);
  const { me, myPaymentMethod } = data ?? {};

  useEffect(() => {
    setDDUser(data?.me);
  }, [data?.me]);

  return useMemo(() => {
    const { profile, organization } = me ?? {};
    const isEmailVerified: boolean = !!me?.email_verified;
    const isUserProfileCreated: boolean = !!profile;

    const refetchMe = async () => {
      return await refetch();
    };

    const isUserProfileValid: boolean = !!(
      profile?.firstName &&
      profile?.lastName &&
      profile?.termsOfService?.acceptedAt &&
      profile?.privacyPolicy?.acceptedAt
    );

    return {
      isLoggedIn: !!me,
      me,
      isLoading: loading,
      error,
      refetchMe,
      isEmailVerified,
      isUserProfileCreated,
      isUserProfileValid,
      userId: me?.id,
      organization: organization ?? null,
      paymentMethod: myPaymentMethod,
    };
  }, [me, loading, error, refetch, myPaymentMethod]);
};
